<template>
  <nav id="navbar" ref="navbar" :class="`navbar sticky-top navbar-expand-lg py-0 ${isScrolled ? 'header-hidden' : ''
    } `" :style="`--header_opacity:${this.header_opacity}`" data-bs-theme="dark">
    <div class="container-fluid header-wrapper px-2">
      <a :class="'navbar-brand'" href="#/">
        <img src="@\assets\egc-logo.png" alt="" class="web-logo" />
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li :class="`nav-item header-link-item ${data.linkType === 'sublinks' ? 'dropdown' : ''
            }`" v-for="(data, index) in datas" :key="index">
            <router-link :class="`nav-link header-link text-center text-uppercase 
                                ${separatePath(data.href) ? 'active' : ''}
                                ${data.linkType === 'sublinks'
                ? 'dropdown-toggle'
                : ''
              }`" :to="`${data.href}`" :role="`${data.linkType === 'sublinks' ? 'button' : ''}`" :data-bs-toggle="`${data.linkType === 'sublinks' ? 'dropdown' : ''
                }`" :aria-expanded="`${data.linkType === 'sublinks' ? 'false' : ''} `">{{ data.name }}
            </router-link>
            <ul class="dropdown-menu" v-if="data.linkType === 'sublinks'">
              <li>
                <a class="dropdown-item" v-for="sub in data.sublinks" :key="sub" :href="`#${sub.href}`">{{ sub.name
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
        <div class="search-signin-wrapper" v-if="this.showSigninSearch.value === 'yes'">
          <div class="input-group search-group">
            <span class="input-group-text" id="basic-addon1"><i class="fa-solid fa-magnifying-glass"></i></span>
            <input type="text " class="form-control search-field" placeholder="Search" aria-label="Search"
              aria-describedby="basic-addon1" />
          </div>
          <button class="btnSignin text-uppercase">Sign in</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  /* eslint-disable */
  const getShowSigninSearch = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(api.parameter.get("param/showsearchsignin"));
      });
    });
  };
  import NavLinks from "@/components/NavLinks.vue";
  import { mapMutations, mapGetters } from "vuex";
  import { api } from "@/service/webapi";
  import { ref } from "vue";
  export default {
    name: "Header",
    components: { NavLinks },
    async setup() {
      const showSigninSearch = ref(await getShowSigninSearch());
      return {
        showSigninSearch,
      };
    },
    data() {
      return {
        screenWidth: window.innerWidth,
        fullpathSegments: [],
        datas: [
          {
            name: "Home",
            href: "/",
            linkType: "new",
          },

          {
            name: "News",
            href: "/news",
            linkType: "new",
          },
          {
            name: "Projects",
            href: "/projects",
            linkType: "new",
          },
          // {
          //   name: "our company",
          //   href: "/company",
          //   linkType: "new",
          // },
          {
            name: "Career",
            href: "/career",
            linkType: "new",
          },
        ],
        isScrolled: false,
        prevScrollPos: 600,
        header_opacity: 0.2,
        prevScrollpos: window.pageYOffset,
        isScrolling: false,
      };
    },
    computed: {
      ...mapGetters(["getSectionToScroll"]),
      isCollapsed() {
        return this.screenWidth < 992;
      },
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
      window.addEventListener("scroll", this.throttleScroll);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
      window.removeEventListener("scroll", this.throttleScroll);
    },
    methods: {
      ...mapMutations(["setSectionToScroll"]),
      handleResize() {
        this.screenWidth = window.innerWidth;
      },
      throttleScroll() {
        if (!this.isScrolling) {
          this.handleScroll();
          this.isScrolling = true;
          setTimeout(() => {
            this.isScrolling = false;
          }, 50);
        }
      },
      hover(e, type) {
        let num = e.srcElement.dataset.index;
        if (num && type === "sublinks") {
          $(`#navDropDown_${num}`).dropdown("show");
        }
      },
      leave(e, type) {
        let num = e.srcElement.dataset.index;
        if (num && type === "sublinks") {
          $(`#navDropDown_${num}`).dropdown("hide");
        }
      },
      separatePath(x) {
        const path = this.$route.fullPath;
        const segments = path.split("/");
        if (segments[1] == x.replace("/", "")) {
          return true;
        }
        return false;
      },
      navigateClick(type, path) {
        this.$nextTick(() => {
          if (type === "new") {
            this.$router.push(`/${path}`);
          }
          if (type === "within") {
            const scrolltoElement = this.$refs[path];
            scrolltoElement.scrollIntoView({ behavior: "smooth" });
          }
          if (type === "sublinks") {
          }
        });
      },
      handleScroll() {
        const currentScrollPos = window.pageYOffset;
        if (this.prevScrollpos > currentScrollPos) {
          this.$refs.navbar.style.top = "0";
        } else {
          if (currentScrollPos > 300) {
            this.$refs.navbar.style.top = "-80px"; // adjust the value based on the height of the navbar
          }
        }
        this.prevScrollpos = currentScrollPos;
      },
      // handleScroll() {
      //   const htmlElement = document.documentElement;
      //   const percentageOfScrolledHeight = Math.min(
      //     (htmlElement.scrollTop / htmlElement.clientHeight) * 100,
      //     100
      //   );
      //   const headerOpacity =
      //     (Math.min(percentageOfScrolledHeight, 10) / 10) * 0.8 + 0.2;
      //   this.header_opacity = headerOpacity;
      //   const currentScrollPos = window.pageYOffset;

      //   if (currentScrollPos > this.prevScrollPos) {
      //     this.isScrolled = true;
      //   } else {
      //     this.isScrolled = false;
      //   }
      // },
    },
  };
</script>

<style lang="scss" scoped>
  .navbar {
    transition: all 0.4s ease;
    backdrop-filter: blur(20px);
    z-index: 999;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      height: 3px;
    }
  }

  .page-header-container {
    width: 100%;
    max-width: $site-width;
    height: $header-height;
    margin-inline: auto;
    isolation: isolate;
  }

  .web-logo {
    @include imageSize($logo-height, "auto");
  }

  .navbar-toggler {
    border: none !important;
  }

  .navbar-brand {
    z-index: 999;
    position: absolute;
    left: 12;
  }

  ///for NavLinks
  .navbar-collapse {
    display: flex;
    place-content: center center;
  }

  .header-wrapper {
    width: 100%;
    max-width: $site-width;
    position: relative;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    height: $header-height;
  }

  .nav-link.header-link.active::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    -webkit-clip-path: polygon(10% 0, 90% 0, 70% 100%, 30% 100%);
    clip-path: polygon(10% 0, 90% 0, 70% 100%, 30% 100%);
    background: linear-gradient(to bottom, #0f0f0c, #521313);
    z-index: -1;
  }

  .nav-link.header-link.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 45%;
    height: 3px;
    border-radius: 20px;
    background: red;
    margin: 0 !important;
  }

  .navbar-collapse {
    transition: all 0.4s ease;

    & .navbar-nav {
      height: $header-height;
    }
  }

  .search-signin-wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-inline: 12px;
    gap: 1rem;

    .search-group {
      position: relative;
      width: 100%;
      max-width: 150px;
      height: 100%;
      margin-right: 0.5rem;
      isolation: isolate;
      overflow: hidden;
      border-radius: 5px;
      border: none;

      span {
        padding-inline: 0.5rem;
        background: white;
        border: none !important;
      }

      .search-field {
        background: white;
        border: none !important;
      }
    }

    .search-group::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.3) inset;
      z-index: 999;
      pointer-events: none;
    }

    .btnSignin {
      width: 100%;
      border: none;
      max-width: 5rem;
      border-radius: 5px;
      isolation: isolate;
      position: relative;
      overflow: hidden;
      font-size: 13px;
      background: #bb060d;
    }

    .btnSignin::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 7px 7px rgba(255, 255, 255, 0.3) inset;
      z-index: 999;
    }
  }

  .nav-item.header-link-item {
    position: relative;
    width: 100%;
    min-width: 7.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-link.header-link {
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
    z-index: 1000;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.3s;
    isolation: isolate;
  }

  .nav-link.header-link.active {
    color: red !important;
  }

  .dropdown-toggle::after {
    border: none !important;
    padding: 0 !important;
  }

  .navDropdown.show {
    transform: translateX(-50%) !important;
  }

  @keyframes animateNav {
    from {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: 991px) {
    .page-header-container {
      display: flex;
      place-content: center space-between;
    }

    .navbar-brand {
      position: unset;
    }

    .navbar {
      height: $header-height;
      padding-block: 8px !important;
    }

    .navbar-collapse {
      display: block;
    }

    .nav-link.header-link.active::before {
      content: none;
    }

    .nav-link.header-link.active::after {
      content: none;
    }

    .header-wrapper {
      height: auto;
    }

    .search-signin-wrapper {
      display: none !important;
    }

    // #navbarNavDropdown{
    //     display:block !important;
    //     position:none;
    // }
    .navbar-collapse {
      margin-block-start: 0.5rem;
      display: none;
    }

    .nav-item.header-link-item {
      background-color: rgba(15, 15, 12, 1);
    }

    .navbar-collapse.show {
      display: block;

      .navbar-nav {
        background: #0f0f0c;
        height: 100% !important;
      }
    }

    .navbar-collapse.collapsing {
      display: block;
      background: #0f0f0c;
    }
  }
</style>
