import { createStore } from 'vuex'
import { api } from "@/service/webapi"

/* eslint-disable */
export default createStore({
  state: {
    adminState: "",
    headers: {},
    sectionToScroll: null,

  },
  getters: {

    getAdminState(state, getters) {
      return state.adminState;
    },
    getColHeaders(state, getters) {
      return state.headers;
    },
    getSectionToScroll(state, getters) {
      return state.sectionToScroll;
    }
  },
  mutations: {
    setSectionToScroll(state, payload) {
      state.sectionToScroll = payload;
    },
    setAdminState(state, payload) {
      state.adminState = payload
    },
    setColHeaders(state, payload) {
      state.headers = payload
    }

  },
  actions: {
    async initializeStore() {
      return this.state.adminState;
    },
    async getHeaders({ commit }, payload) {
      // payload has collection name, customapiname
      try {
        let data = {}
        data = await api[payload].get(`fields`);
        commit('setColHeaders', data);
      } catch (error) {
        console.error('Error prefetching data:', error);
      }
    }
  },
  modules: {
  }
})
